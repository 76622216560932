<template>
    <div class="IMT">
        <div class="banner" id="banner">
            <auto-img src="http://oss.imttech.cn/case/imt_top_banner.jpg" mul="42%" />

            <div class="banner-btns">
                <div class="banner-btn">
                    <img src="../assets/imt_topbanner_icon_3d.png" alt="" />

                    <el-link class="btn" :underline="false" :href="url" target="_blank">
                        <div class="text">{{ $t('dian-ji-guan-kan-imt-3d-zhan-ting') }}</div>
                    </el-link>
                    <!-- <el-link type="primary" :underline="false" href="" target="_blank"></el-link> -->
                </div>

                <!-- <div class="banner-btn video" @click="showViedo = true">
                    <img src="../assets/imt_topbanner_icon_xuanchuanpian.png" alt="" />

                    <div class="btn">
                        <div class="text">点击观看 IMT宣传片</div>
                    </div>
                    
                </div> -->
            </div>
        </div>

        <div class="info">
            <div class="info-main">
                <div class="title fontYahei">{{ $t('imt-ke-ji-ping-tai') }}</div>
                <div class="content">
                    {{ getName(introduce) }}
                </div>
                <div class="btn-list">
                    <div>{{ $t('gao-du-ji-ju-xing') }}</div>
                    <div>{{ $t('gao-jing-mi-chan-pin-ji-ji-shu') }}</div>
                    <div>{{ $t('gao-du-zhuan-ye-hua') }}</div>
                </div>
            </div>
        </div>

        <title-driver class="hot-title" id="overview">
            <span slot="title">
                <span>{{ $t('imt-ke-ji') }}·</span>
                <!-- <span class="colorWarning big">4</span> -->
                <span class="colorWarning">{{ $t('ping-tai-gai-kuang') }}</span>
            </span>
            <span slot="sub">{{ $t('zhi-neng-zhi-zao-chan-ye-lian-sheng-tai-xi-tong') }}</span>
        </title-driver>
        <div class="list">
            <Core v-for="(item, index) in modulars" :key="index" :index="index + 1" :info="item"></Core>
        </div>

        <title-driver class="hot-title">
            <span slot="title">
                <span>{{ $t('imt-ke-ji') }}·</span>
                <!-- <span class="colorWarning big">5</span> -->
                <span class="colorWarning">{{ $t('ping-tai-you-shi') }}</span>
            </span>
            <span slot="sub">{{ $t('zhi-neng-zhi-zao-chan-ye-lian-sheng-tai-xi-tong') }}</span>
        </title-driver>

        <div class="advantages">
            <advantage
                v-for="(item, index) in advantages"
                :key="index"
                :img="item.img"
                :name="getName(item.name)"
                :icon="item.icon"
            />
        </div>

        <title-driver class="hot-title">
            <span slot="title">
                <span>{{ $t('imt-ke-ji') }}·</span>
                <!-- <span class="colorWarning big">11</span> -->
                <span class="colorWarning">{{ $t('jing-xi-hua-fu-wu') }}</span>
            </span>
            <span slot="sub">{{ $t('zhi-neng-zhi-zao-chan-ye-lian-sheng-tai-xi-tong') }}</span>
        </title-driver>

        <div class="service" id="service">
            <el-tabs v-model="activeServiceName" class="chooseTab">
                <el-tab-pane :name="`${index}`" :key="index" v-for="(item, index) in service1s">
                    <Label slot="label" :value="`${index}`" :chooseValue="activeServiceName" :icon="item.icon">
                        <template slot="title">{{ getName(item.name) }}</template>
                    </Label>
                    <ServiceWithCase :key="index" :info="item"></ServiceWithCase>
                </el-tab-pane>
            </el-tabs>
        </div>

        <div class="service">
            <el-tabs v-model="activeServiceName2" class="chooseTab">
                <el-tab-pane :name="`${index}`" :key="index" v-for="(item, index) in service2s">
                    <Label slot="label" :value="`${index}`" :chooseValue="activeServiceName2" :icon="item.icon">
                        <template slot="title">{{ getName(item.name) }}</template>
                    </Label>

                    <Service :key="index" :info="item"></Service>
                </el-tab-pane>
            </el-tabs>
        </div>

        <title-driver class="hot-title" id="connect">
            <span slot="title">
                <span>{{ $t('imt-ke-ji') }}·</span>
                <!-- <span class="colorWarning big">11</span> -->
                <span class="colorWarning">{{ $t('lian-xi-wo-men') }}</span>
            </span>
            <span slot="sub">{{ $t('zhi-neng-zhi-zao-chan-ye-lian-sheng-tai-xi-tong') }}</span>
        </title-driver>

        <div class="address">
            <Map />

            <div class="address-text">
                <div class="address-info">
                    <img src="../assets/footer_icon_dianhua@2x.png" alt="" />
                    <span>86-21-60797117</span>
                </div>
                <div class="address-info">
                    <img src="../assets/footer_icon_youjian@2x.png" alt="" />
                    <span>imttech@itoc.com.cn</span>
                </div>
                <div class="address-info">
                    <img src="../assets/footer_icon_dizhi@2x.png" alt="" />
                    <span>{{ $t('zhong-guo-shang-hai-zi-you-mao-yi-shi-yan-qu-fu-te-dong-san-lu-526-hao') }}</span>
                </div>
            </div>
        </div>

        <el-dialog
            class="videoDialog"
            destroy-on-close
            center
            append-to-body
            :visible.sync="showViedo"
            @close="closeEvent"
            width="90%"
        >
            <video
                :src="videoUrl"
                controlsList="nodownload noremote footbar"
                controls="controls"
                style="height: 100%; max-width: 100%;"
                oncontextmenu="return false;"
                onmouseleave="leaveVideo(this)"
                ref="video"
                v-if="showViedo"
            >
                您的浏览器不支持 video 标签。
            </video>

            <div class="close" @click="showViedo = false">关闭</div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import Advantage from '../components/imt/Advantage.vue';
import Core from '../components/imt/Core.vue';
import Label from '../components/imt/Label';
import ServiceWithCase from '../components/imt/ServiceWithCase.vue';
import Service from '../components/imt/Service.vue';
import Map from '../components/imt/Map';
export default {
    name: 'IMT',
    data() {
        return {
            modulars: [
                {
                    imgs: [
                        'http://oss.imttech.cn/case/imt_hexin_img_01_a.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_01_b.jpg'
                    ],
                    title: {
                        chName: '高端的服务定位',
                        enName: 'High-end Service Positioning'
                    },
                    sub: {
                        chName: '打造功能集成的智能制造服务产业平台',
                        enName:
                            'To build a service platform with integrated functions for intelligent manufacturing industry'
                    },
                    content: {
                        chName:
                            '外高桥智能制造服务产业园（IMT），坐落于中国（上海）自由贸易试验区，是一个服务模式、服务能级全面创新升级，针对高端智能制造行业的国际贸易便利化和专业化服务平台。',
                        enName:
                            'Waigaoqiao Intelligent Manufacturing Trading Center (IMT), located in the Pilot Free Trade Zone (Shanghai), China, is an industry service platform focusing on international trade facilitation and specialized services with innovative and upgraded service modules for high-end intelligent manufacturing industry.'
                    }
                },
                {
                    imgs: [
                        'http://oss.imttech.cn/case/imt_hexin_img_02_a.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_02_b.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_02_c.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_02_d.jpg'
                    ],
                    title: {
                        chName: '成熟的运营模式',
                        enName: 'Mature Business Model'
                    },
                    sub: {
                        chName: '集聚性、专业化、便利化',
                        enName: 'Agglomeration, Specialization, Facilitation'
                    },
                    content: {
                        chName:
                            '产业园针对进口各种高精端加工及检验检测等设备，已形成集中的设备展示中心、仓储分拨中心、维修中心及检测中心，为客户提供保税展示与演示、展品物流、零部件分拨、贸易代理、仓储管理、物业租赁、检测认证、专业培训、技术交流等服务。',
                        enName:
                            'We have set up integrated centralized display center, distribution center and inspection center for all kinds of imported high-end machining tools and testing equipments, providing comprehensive services including display and demonstration, exhibition logistics, trading agency, warehousing and distribution, premises rental, testing and certification, technical exchange, specialized training, etc.'
                    }
                },
                {
                    imgs: [
                        'http://oss.imttech.cn/case/imt_hexin_img_03_a.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_03_b.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_03_c.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_03_d.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_03_e.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_03_f.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_03_g.jpg'
                    ],
                    title: {
                        chName: '顶级机床及设备',
                        enName: 'Machine Tools and Equipment at the cutting edge of intelligent manufacturing industry'
                    },
                    sub: { chName: '高精密、智能化', enName: 'High precision and intelligent' },
                    content: {
                        chName:
                            '集聚了国际领先的精密数控机床、机床工具、智能机器人、3D 打印、高精密检测设备等企业入驻。',
                        enName:
                            'Globally leading companies of precision CNC machine tools, cutting tools, intelligent robots, 3D printing and high precision measuring equipment have gathered here.'
                    }
                },
                {
                    imgs: [
                        'http://oss.imttech.cn/case/imt_hexin_img_04_a.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_04_b.jpg'
                    ],
                    title: { chName: '行业协会、专业机构', enName: 'Association and Institution' },
                    sub: { chName: '国际化、专业化', enName: 'Internationalization, Specialization' },
                    content: {
                        chName:
                            '我们与国际化的行业协会和专业的服务机构合作，包括上海瑞士中心、美国机床协会、德国机床制造商协会、机械工业机床产品质量检测中心、上海浦东智能制造协会等，共同为客户提专业、权威的综合服务。',
                        enName:
                            'We have cooperated with international industry associations and professional service organizations, including Swiss Center Shanghai, Association for Manufacturing Technology, VDW, Shanghai Inspecting & Testing Center of Machine Tool Products Quality of CMIF, Intelligent Manufacturing Association Shanghai, to provide professional and authoritative services for clients.'
                    }
                },
                {
                    imgs: [
                        'http://oss.imttech.cn/case/imt_hexin_img_05_a.jpg',
                        'http://oss.imttech.cn/case/imt_hexin_img_05_b.jpg'
                    ],
                    title: { chName: '特色平台', enName: 'Characteristic Platform' },
                    sub: { chName: '权威、专业', enName: 'Authoritative and Specialized' },
                    content: {
                        chName:
                            '瞄准科技前沿和高端产业，上海集中推出26个面积在3至5平方公里的特色产业园区，我们被评选为智能制造板块8个特色园区之一，将在上海市经信委智能制造产业推进处的专业指导下全力打造智能制造产业发展新高地。凭借着一直坚持为客户提供的长短结合的展示推广服务经验，我们获批成为中国国际进口博览会智能及高端设备板块中唯一“6天+365天”常年展示交易平台。',
                        enName:
                            'Aiming at the cutting edge technology and high-end industries, Shanghai has launched 26 characteristic industrial parks covering the area of 3 to 5 square kilometers. IMT has been selected as one of the eight characteristic parks in the intelligent manufacturing sector. Under the professional guidance of the Intelligent Manufacturing Industry Promotion Department of SHEITC, we will strive to create a new “highland” for the development of intelligent manufacturing industry.We are licensed to be the only "6+ 365" permanent exhibition and trade platform of intelligent manufacturing by Shanghai Municipal Commission of Commerce relying on our years of experience on the combination of long-term and short-term exhibition and promotion services.'
                    }
                }
            ],
            introduce: {
                chName:
                    '外高桥智能制造服务产业园（IMT）上线智羿园区（T-PARK），推出在线贸易服务，依托IMT行业高度集聚性、高度专业化的优势，引进全球制造业高精密机械设备及先进技术，打破物理空间局限性，拓宽功能服务，构建活跃的智能制造生态圈，使企业在资源整合的生态中共同发展。从设备供应商到采购商，从国外工厂到国内用户，实现智能制造产业链各企业间“纵向+横向”的信息集成，为制造商、经销商、买家和服务商之间开展国际和国内贸易拓宽渠道、消除壁垒。',
                enName:
                    'Waigaoqiao Intelligent Manufacturing Trading Center (IMT), the first industry  park on T-PARK, introduces  high-precision  equipment  and  advanced technologies of global manufacturing industry relying on the advantages of industry agglomeration and specialization, and breaks the limitations of physical space and expands functional services to build an active ecosystem of intelligent manufacturing and to achieve common development by resource integration. From equipment suppliers to purchasers and from foreign factories to domestic users, we can realize "vertical + horizontal" information integration among enterprises in the field of intelligent manufacturing industry, broaden channels and	eliminate	barriers	for	international	and	domestic	trade	among manufacturers, distributors, buyers and service providers.'
            },
            advantages: [
                {
                    img: 'http://oss.imttech.cn/case/imt_youshi_img_02.png',
                    icon: 'http://oss.imttech.cn/case/imt_youshi_icon_02.png',
                    name: {
                        chName:
                            '强大的行业集聚性贸易服务平台<br/>专业、专项、专注<br/>充分利用自贸区国际贸易便利化优势<br/>为智能制造产业客户提供更大的灵活性和实效性优势',
                        enName:
                            'A powerful industry agglomeration trade service platform, making full use of the advantages of international trade facilitation of free trade zone to provide flexibility and effectiveness for customers in intelligent manufacturing industry.'
                    }
                },
                {
                    img: 'http://oss.imttech.cn/case/imt_youshi_img_04.png',
                    icon: 'http://oss.imttech.cn/case/imt_youshi_icon_04.png',
                    name: { chName: '庞大的专业客户集群', enName: 'Huge ending customer cluster in the industry.' }
                },
                {
                    img: 'http://oss.imttech.cn/case/imt_youshi_img_01.png',
                    icon: 'http://oss.imttech.cn/case/imt_youshi_icon_01.png',
                    name: {
                        chName: '惠享自贸区贸易、财政、先试先行政策，为企业在该区域开展相关业务给予直接的政策支撑',
                        enName:
                            'Enjoy the pilot free trade zone policy on trade and finance; provide direct policy support for enterprises to carry out related business in the region.'
                    }
                },
                {
                    img: 'http://oss.imttech.cn/case/imt_youshi_img_05.png',
                    icon: 'http://oss.imttech.cn/case/imt_youshi_icon_05.png',
                    name: {
                        chName: '丰富的行业交流、政企交流活动',
                        enName: 'Various industry fairs, government enterprise communication events.'
                    }
                },

                {
                    img: 'http://oss.imttech.cn/case/imt_youshi_img_03.png',
                    icon: 'http://oss.imttech.cn/case/imt_youshi_icon_03.png',
                    name: {
                        chName: '与各国行业协会建立长期合作关系<br/>发展智能制造',
                        enName:
                            'Long-term cooperative relationship with associations of various countries to develop intelligent manufacturing in China'
                    }
                }
            ],
            activeServiceName: '0',
            activeServiceName2: '0',
            service1s: [
                {
                    name: { chName: '综合物流', enName: 'Integrated Supply Chain Service' },
                    icon: '&#xe60b;',
                    content: {
                        chName:
                            '配备专业的贸易及物流服务团队，为客户提供全面的贸易物流解决方案，包括进出口业务服务、运输服务、保税展示物流、仓储管理与分拨、机械设备维修物流等。',
                        enName:
                            'We have professional trade and logistics service team to provide customers with comprehensive supply chain solutions, including import and export business services, transportation services, bonded exhibition logistics, warehouse management and distribution (bonded & non-bonded), maintenance logistics, etc.'
                    },
                    cases: [
                        {
                            imgs: [
                                'http://oss.imttech.cn/case/imt_fuwu_wuliu_case01_a.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_wuliu_case01_b.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_wuliu_case01_c.jpg'
                            ],
                            name: {
                                chName: '中国国际进口博览会保税参展物流服务',
                                enName: 'Boned Exhibition Logistics Service for CIIE'
                            },
                            content: {
                                chName:
                                    '首届中国国际进口博览会（CIIE）已于2018年11月5至10期间在上海顺利召开，智能装备展区中有多家IMT客户参展，其中展机直接从自贸区出展的客户共有9家，参展高端机床设备共计21台，总货值超过1000万美元。IMT物流团队为客户提供从展前布展到展后撤展的全程展品物流服务，负责保税展机的所有海关及物流手续。',
                                enName:
                                    'During the first China International Import Expo, several clients of IMT brought their latest products to the big event. 21 of the equipment from 9 clients, with total value more than 10 million dollars, were transported from FTZ to the exhibition. We have provided our unique boned exhibition logistics service which covers all customs and logistics procedures for our clients to enjoy the boned exhibition policy during the whole period from exhibition move-in through move-out. '
                            }
                        },
                        {
                            imgs: [
                                'http://oss.imttech.cn/case/imt_fuwu_wuliu_case02_a.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_wuliu_case02_b.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_wuliu_case02_c.jpg'
                            ],
                            name: {
                                chName: 'Liebherr保税仓储与物流服务',
                                enName: 'Warehousing and Logistics Services for Liebherr'
                            },
                            content: {
                                chName:
                                    '我们为利勃海尔中国提供综合物流及保税仓储服务，为其配备了独立恒温恒湿仓库，并提供保税仓储管理，以及包括进口/出口业务流程操作、进境备案、保税区清关、物流运输与装卸在内的综合贸易物流服务。高效的保税仓储管理和完善的物流服务可为其节约运营成本，助力提高业务运转效率。',
                                enName:
                                    'We provide comprehensive logistics and bonded warehousing services for Liebherr. We offer independent warehouse with constant temperature and humidity and provide bonded warehouse management. We also provide integrated trading logistics services including operation of import/export business process, FTZ entry record, customs clearance, transportation and handling, etc. Efficient bonded warehouse management and perfect logistics services have saved its operating costs and help to improve efficiency of business operation.'
                            }
                        }
                    ]
                },
                {
                    name: { chName: '商务租赁', enName: 'Premises Rental Service' },
                    icon: '&#xe611;',
                    content: {
                        chName:
                            '拥有多种功能的物业空间，包括商务办公楼、设备展示体验馆、多功能会议厅、多媒体中心，并提供专业的物业管理服务。',
                        enName:
                            'We have property space with different functions, including office, bonded showroom, conference room, multimedia center, and we provide professional property management services. '
                    },
                    cases: [
                        {
                            imgs: [
                                'http://oss.imttech.cn/case/imt_fuwu_zulin_case01_a.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_zulin_case01_b.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_zulin_case01_c.jpg'
                            ],
                            name: { chName: 'GF 加工方案', enName: 'GF Machining Solutions' },
                            content: {
                                chName:
                                    '乔治费歇尔精密机床（上海）有限公司是瑞士GF加工方案上海子公司，2014年迁址至外高桥智能制造服务产业园，并在园区设立亚洲和中国总部。总部面积达3000平方米，是亚洲和中国管理团队所在地，也是其在亚洲地区最大最新的综合技术服务中心。同时，GF加工方案在产业园内设立了亚洲自动化演示中心，以及面向公司内部及外部的亚洲技术培训学院，奠定了其在亚太地区的重要地位。同时，产业园的保税展示及演示功能，也为其最新技术和产品的首发亮相提供最优的场所与环境。',
                                enName:
                                    'In 2014, GF Machining Solutions moved into IMT. The total area of this Asia HQ, the biggest integrated technical service center, reached 3000 square meters with its management team of China. It also set up the automated demonstration center and Asia academy center here to support the whole Chinese market.'
                            }
                        },
                        {
                            imgs: [
                                'http://oss.imttech.cn/case/imt_fuwu_zulin_case02_a.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_zulin_case02_b.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_zulin_case02_c.jpg'
                            ],
                            name: {
                                chName: 'INDEX',
                                enName: 'INDEX'
                            },
                            content: {
                                chName:
                                    'INDEX集团起源于德国，是全球一流的数控车床制造商。2017年，INDEX集团入驻外高桥智能制造服务产业园，成立了全新的技术中心和展厅，新的技术服务中心为两层，建筑面积近1500平方米，白绿相间的简约设计极具现代感。宽敞通透的展厅中展示了因代克斯集团最新系列产品，并配以自主研发的数控机床控制系统——Xpanel i4.0进行生产实时管理系统演示。该技术中心集合公司运营管理、结算、设备展示、演示、销售、技术支持和培训等功能，并与其位于太仓的生产基地协同，可为亚太区客户提供更加完善的服务。',
                                enName:
                                    'Index set up its Asia-Pacific Technical Center, reaching 1500 square meters, at IMT with comprehensive functions of management, settlement, demonstration, trading, customer services, training and technical support, coordinating its production base in Taicang to better support Chinese market.'
                            }
                        }
                    ]
                },
                {
                    name: { chName: '检测认证', enName: 'Testing and Certification' },
                    icon: '&#xe60d;',
                    content: {
                        chName:
                            '行业专业检测机构，为客户提供进口机床法定检验、机床精度及性能检测等服务。实行进口机床现场法定检验及采信第三方检测报告。',
                        enName:
                            'We have professional testing institutions onsite, providing customers with statutory inspection for import machine tools, accuracy testing and performance testing for machine tools, etc.'
                    },
                    cases: [
                        {
                            imgs: [
                                'http://oss.imttech.cn/case/imt_fuwu_jiance_case01_a.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_jiance_case01_b.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_jiance_case01_c.jpg'
                            ],
                            name: {
                                chName: '机械工业机床产品质量检测中心',
                                enName:
                                    'Shanghai Inspecting & Testing Center of Machine Tool Products Quality of CMIF (SMTITC)'
                            },
                            content: {
                                chName:
                                    '机械工业机床产品质量检测中心（上海）坐落于产业园内，是机床制造领域产品质量监督检验和性能测试的专业检测机构。可进行出入境检验检疫局系统委托的进出口机床法定检验, 以及各类机床用户﹑制造商及销售商委托的机床精度和性能检测等服务。',
                                enName:
                                    'Shanghai Inspecting & Testing Center of Machine Tool Products Quality of CMIF (SMTITC), located at IMT, is the specialized testing institution for machine tool industry, providing statutory inspection services authorized by entry-exit Inspection & Quarantine Bureau and other accuracy testing and performance testing services.'
                            }
                        }
                    ]
                },
                {
                    name: { chName: '专业培训', enName: 'Vocational Education' },
                    icon: '&#xe60e;',
                    content: {
                        chName:
                            '园区设立 “上海市智能制造高技能人才培养基地外高桥分基地”，是由上海市智能制造产业协会、上海浦东智能制造协共同组建的专业化培训平台，旨在服务更多智能制造优秀企业，以产业发展、企业需求为导向，加快为企业输送智能制造高技能人才。',
                        enName:
                            '“Waigaoqiao Sub base of Shanghai intelligent manufacturing high skilled personnel training base”, located at IMT, is a professional training platform jointly established by Shanghai Intelligent Manufacturing Industry Association and Shanghai Pudong Intelligent Manufacturing Association, aims to serve more intelligent manufacturing enterprises and speed up the output of high skilled talents for enterprises with the guidance of industrial development and enterprise demand.'
                    },
                    cases: [
                        {
                            imgs: [
                                'http://oss.imttech.cn/case/imt_fuwu_peixun_case01_a.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_peixun_case01_b.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_peixun_case01_c.jpg'
                            ],
                            name: {
                                chName: '工业大数据助推智能工厂建设',
                                enName: 'Promote the construction of intelligent factory with industrial big data'
                            },
                            content: {
                                chName:
                                    '2019年11月，首期专题培训研修班“工业大数据助推智能工厂建设”开班，邀请了协会部分会员企业相关负责人及行业资深专家讲解授课，旨在帮助制造业企业更好理解大数据技术如何融入智能制造过程的各个环节，实现生产精准化、智能化，适应本市“创新驱动发展、经济转型升级”的需要。',
                                enName:
                                    'The first training seminar, "Promote the construction of intelligent factory with industrial big data" was held in November, 2019. The seminar covered various topics such as industrial information security and big data analysis, and organized site visiting of Siemens Digital Experience Center.'
                            }
                        }
                    ]
                },
                {
                    name: { chName: '商务咨询', enName: 'Business Consulting' },
                    icon: '&#xe60f;',
                    content: {
                        chName:
                            '配备专业政策咨询及公司事务咨询团队，为客户提供政策咨询、投资咨询、公司事务代理等服务。',
                        enName:
                            'We have professional consulting team for policy and corporate affairs, providing consulting services including policy consulting, investment consulting and business affairs, etc.'
                    },
                    cases: [
                        {
                            imgs: [
                                'http://oss.imttech.cn/case/imt_fuwu_zixun_case01_a.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_zixun_case01_b.jpg',
                                'http://oss.imttech.cn/case/imt_fuwu_zixun_case01_c.jpg'
                            ],
                            name: { chName: '通快', enName: 'TRUMPF' },
                            content: {
                                chName:
                                    '我们成功助力通快科技（上海）有限公司于2019年9月认定为上海市跨国公司地区总部（亚太区），将作为德国通快集团在亚太地区的唯一高能级总部建立财务控制管理中心、外币跨境现金池、亚太区备件物流中心以及激光应用的亚太区服务中心，承载通快集团发展中国市场乃至亚太地区市场发展的期望，进一步提升在中国内地的投资和投资管理能力，拓展市场份额。',
                                enName:
                                    'In September 2019, we have successfully helped TRUMPF Technology (Shanghai) Co., Ltd. to be recognized as the regional headquarters of multinational company in Shanghai (Asia Pacific region). It was authorized to be the only high-level headquarters of TRUMPF Group in Asia Pacific region to establish financial control management center, foreign currency cross-border cash pool, Asia Pacific spare parts logistics center and Asia Pacific Service Center for laser application. It will carry the expectations of TRUMPF Group in developing the Chinese market and even the Asia Pacific market, further enhance the investment and management ability in the mainland of China and expand the market share.'
                            }
                        }
                    ]
                }
            ],
            service2s: [
                {
                    name: { chName: '参展服务', enName: 'Bonded Exhibition Service' },
                    icon: '&#xe60c;',
                    color: '#64776F',
                    content: {
                        chName: '为客户提供设备保税参展服务，从设备到港到设备进馆完成调试的全流程服务。',
                        enName:
                            'We provide bonded exhibition service for customers, from the arrival of equipment at port to the status test completion in the exhibition hall.'
                    },
                    img: 'http://oss.imttech.cn/case/imt_fuwu_img_062x.jpg'
                },
                {
                    name: { chName: '行业交流', enName: 'Industry Communication' },
                    icon: '&#xe609;',
                    color: '#B3775B',
                    content: {
                        chName: '组织筹办国际性的行业展会、组织政企交流活动、举办研讨会、技术论坛等专业活动。',
                        enName:
                            'Organize international trade show, organize government enterprise communication activities, and hold seminars, technology forums and other professional activities.'
                    },
                    img: 'http://oss.imttech.cn/case/imt_fuwu_img_072x.jpg'
                },
                {
                    name: { chName: '融资租赁', enName: 'Financial Lease' },
                    icon: '&#xe608;',
                    color: '#BF782C',
                    content: {
                        chName:
                            '与银行系统进行金融战略合作，搭建产融合作平台，为平台智能制造企业和相关重点项目配置优质金融服务解决方案。',
                        enName:
                            'Cooperate with banking system, build industry-finance cooperation platform, and provide high-quality financial service solutions for intelligent manufacturing enterprises and related key projects.'
                    },
                    img: 'http://oss.imttech.cn/case/imt_fuwu_img_082x.jpg'
                },
                {
                    name: { chName: '维修', enName: 'Maintenance' },
                    icon: '&#xe607;',
                    color: '#204758',
                    content: {
                        chName: '整合专业技术团队资源，为客户提供高效便捷的维修服务。',
                        enName:
                            'Integrate resources of professional technical team to provide efficient and convenient maintenance service for customers.'
                    },
                    img: 'http://oss.imttech.cn/case/imt_fuwu_img_092x.jpg'
                },
                {
                    name: { chName: '产能共享', enName: 'Capacity Sharing' },
                    icon: '&#xe60a;',
                    color: '#88785B',
                    content: {
                        chName: '整合生产资源，为客户提供产能供应与需求的配置服务',
                        enName:
                            'Integrate production resources to provide customers with capacity allocation services of supply and demand.'
                    },
                    img: 'http://oss.imttech.cn/case/imt_fuwu_img_102x.jpg'
                },
                {
                    name: { chName: '技术系统集成', enName: 'Technology & System Integration' },
                    icon: '&#xe60b;',
                    color: '#5C493B',
                    content: {
                        chName: '整合行业众多优质资源，为客户寻找最佳的技术系统集成方案。',
                        enName:
                            'Integrate high-quality resources in the industry to find the best system integration solution for customers'
                    },
                    img: 'http://oss.imttech.cn/case/imt_fuwu_img_112x.jpg'
                }
            ],
            url: '',
            showViedo: false
        };
    },
    computed: {
        ...mapState(['userInfo']),
        videoUrl() {
            if (this.isChinese) {
                return 'https://oss.imttech.cn/case/IMTvideo_zh.mp4';
            } else {
                return 'https://oss.imttech.cn/case/IMTvideo_en.mp4';
            }
        }
    },
    mounted() {
        if (this.$route.hash) {
            const hash = this.$route.hash.replace(/#/, '');
            this.$nextTick(() => {
                document.documentElement.scrollTo({
                    top: document.getElementById(hash).offsetTop - 200
                });
            });
        }

        this.$http.get('/sysConfig/get/ThreeD_URL').then(res => {
            this.url = res.value;
        });
    },
    components: {
        Advantage,
        Core,
        Label,
        ServiceWithCase,
        Service,
        Map
    },
    methods: {
        go3d() {
            window.open('http://www.3dvshow.com/demo/waigaoqiao/ifr.html');
        },
        closeEvent() {
            document.exitPictureInPicture();
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../styles/variables.scss';
.IMT {
    background-color: #fff;
}
.banner {
    width: 100%;
    display: block;
    height: auto;
}

.info {
    transform: translateY(-118px);
    @include center-content(460);
    .info-main {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 61px 120px;

        .title {
            font-size: 20px;
            font-weight: bold;
            color: $--color-text-regular;
            line-height: 26px;
        }

        .content {
            font-size: 13px;
            color: $--color-text-regular;
            line-height: 28px;
            margin-top: 35px;
            text-align: center;
        }

        .el-row {
            margin-top: 47px;

            .el-button {
                border: none;
                min-width: 180px;
                font-weight: bold;
                padding: 19px 20px;
            }
        }
    }
}

.btn-list {
    display: flex;
    align-items: center;
    margin-top: 46px;
    div {
        min-width: 180px;
        height: 53px;
        background: rgba(255, 165, 38, 0.12);

        font-size: 14px;
        font-weight: bold;
        color: #ffa526;
        line-height: 53px;
        text-align: center;
        padding: 0 10px;
    }
    div + div {
        margin-left: 15px;
    }
}

.colorWarning {
    color: $--color-warning;

    &.big {
        font-size: 28px;
    }
}

.list {
    margin-bottom: 180px;
}

.advantages {
    @include center-content(460);
    display: flex;
    padding-top: 90px;
    padding-bottom: 160px;

    .advantage {
        width: 20%;
        margin: 0 0.1rem;
    }
}

.service {
    @include center-content(460);
    padding-top: 90px;
    // padding-bottom: 90px;
}
.address {
    @include center-content(460);
}
.banner {
    position: relative;
}

.banner-btns {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 27px 236px 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    .banner-btn + .banner-btn {
        margin-left: 100px;
    }

    &::after {
        position: absolute;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 50%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.8;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        content: '';
    }
}
.banner-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;

    img {
        width: 100px;
        height: 100px;
        display: block;
    }

    .btn {
        font-size: 14px;
        font-weight: bold;
        color: #fdffff;
        line-height: 35px;
        width: 166px;
        height: 35px;
        text-align: center;
        position: relative;
        cursor: pointer;
        display: block;

        .text {
            position: relative;
            z-index: 2;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            transform: skewX(-15deg);
            background-color: #21a8b9;
            z-index: 0;
            transition: transform ease-in-out 0.3s;
        }

        &:hover {
            display: block;
            &::after {
                transform: skewX(0deg);
                background-color: mix(#000000, #21a8b9, 10);
            }
        }
    }
    &.video {
        .btn {
            &::after {
                background-color: #ffa526;
            }
            &:hover {
                &::after {
                    background-color: mix(#000000, #ffa526, 10);
                }
            }
        }
    }
}

.address {
    display: flex;
    align-items: center;
    height: 380px;
    padding-top: 81px;
    padding-bottom: 160px;

    .address-text {
        margin-left: 60px;
        flex-grow: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        position: relative;

        &::after {
            content: '';
            width: 60px;
            height: 60px;
            background: #ffa526;
            opacity: 0.12;
            position: absolute;
            top: 0;
            right: 0;
        }

        &::before {
            content: '';
            width: 30px;
            height: 30px;
            background: #ffa526;
            opacity: 0.48;
            position: absolute;
            top: 70px;
            right: 70px;
        }
    }

    .address-info {
        display: flex;
        align-items: center;
        img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        span {
            font-size: 14px;
            color: #525866;
            line-height: 24px;
        }
    }

    .address-info + .address-info {
        margin-top: 30px;
    }
}

.showVideo {
}

.isEn {
    .banner-btn .btn {
        padding: 0 10px;
        min-width: 116px;
    }

    .address {
        .address-text {
            margin-left: 30px;
        }
    }
}
</style>

<style lang="scss">
.service {
    .el-tabs__active-bar {
        background-color: #0f264d;
    }
}

.isEn {
    .service {
        .label .name {
            font-size: 12px;
            padding: 15px 0;
        }
    }
}

.videoDialog {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-dialog {
        max-width: 900px;
        margin-top: 0px;

        .close {
            position: absolute;
            right: 0px;
            top: -42px;
            width: 71px;
            height: 32px;
            background: #00000015;

            font-size: 12px;
            color: #fdffff;
            line-height: 32px;
            text-align: center;
            cursor: pointer;

            &:hover {
                background: #00000055;
            }
        }
    }
    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        padding: 0;

        video {
            display: block;
            height: auto;
            width: 100%;
            outline: none;
        }
    }
}
</style>
