<template>
    <el-row class="service-box" type="flex">
        <el-col class="service-left">
            <div class="service-text">
                <div class="text1">
                    {{ getName(info.name) }}
                </div>
                <div class="text2 scrollbar">
                    {{ getName(info.content) }}
                </div>

                <img src="../../assets/IMT/icon_more_heise.png" alt="" />

                <div class="paginations">
                    <div
                        class="pagination-item"
                        v-for="(item, index) in info.cases"
                        :key="index"
                        @click="changeCase(index)"
                        :class="{ active: chooseCase === index }"
                    >
                        {{ index + 1 }}
                    </div>
                </div>
            </div>
        </el-col>
        <el-col class="service-center">
            <swiper class="swiperBig swiper" :options="options1" ref="swiperBig">
                <swiper-slide v-for="(img, imgIndex) in nowImgs" :key="imgIndex">
                    <show-text-img
                        :img="img"
                        @mouseover="stopAuto"
                        @mouseout="playAuto"
                        :name="getName(nowCase.name)"
                        :content="getName(nowCase.content)"
                        @imgClick="showAnimate"
                    ></show-text-img>
                </swiper-slide>
            </swiper>
        </el-col>
        <el-col class="service-right">
            <swiper
                class="swiper"
                :class="`service_img${item.order}`"
                :options="options"
                v-for="(item, index) in imgs"
                :key="index"
                :style="{ order: item.order }"
                ref="swiper"
            >
                <swiper-slide v-for="(img, imgIndex) in item.imgs" :key="imgIndex">
                    <show-text-img :img="img" @imgClick="showAnimate" :order="2"></show-text-img>
                </swiper-slide>
            </swiper>
        </el-col>
    </el-row>
</template>

<script>
import ShowTextImg from './ShowTextImg';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'animate.css/animate.compat.css';
export default {
    name: 'ServiceWithCase',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            options: {
                allowTouchMove: false
            },
            options1: {
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                speed: 600,
                preventClicks: false,
                allowTouchMove: false
            },
            orderList: [0, 1, 2],
            chooseCase: 0,
            chooseOrder: 0
        };
    },
    computed: {
        nowCase() {
            return this.info.cases[this.chooseCase];
        },
        nowImgs() {
            return this.nowCase.imgs || [];
        },
        imgs() {
            const list1 = [];
            const list2 = [];
            const list3 = [];

            [...this.info.cases].forEach(item => {
                list1.push(item.imgs[0]);
                list2.push(item.imgs[1]);
                list3.push(item.imgs[2]);
            });

            return [
                {
                    imgs: [...list1],
                    order: this.orderList.indexOf(0)
                },
                {
                    imgs: [...list2],
                    order: this.orderList.indexOf(1)
                },

                {
                    imgs: [...list3],
                    order: this.orderList.indexOf(2)
                }
            ];
        },
        swipers() {
            return this.$refs.swiper
                ? this.$refs.swiper.map(item => {
                      return item.$swiper;
                  })
                : [];
        }
    },
    methods: {
        changeCase(index) {
            this.chooseCase = index;
            this.swipers.forEach(item => {
                item.slideTo(index, 300, true);
            });
        },
        showAnimate({ img, el }) {
            this.$refs.swiperBig.$swiper.slideTo(this.nowImgs.indexOf(img), 300, false);
        },
        stopAuto() {
            this.$refs.swiperBig.$swiper.autoplay.stop();
        },
        playAuto() {
            this.$refs.swiperBig.$swiper.autoplay.start();
        }
    },
    components: {
        ShowTextImg,
        Swiper,
        SwiperSlide
    }
};
</script>

<style lang="scss" scoped>
.service-box {
    height: 460px;
    padding: 30px 0;

    .service-left {
        height: 100%;
        width: 290px;
        flex-shrink: 0;

        .service-text {
            background: #8f553e;
            padding: 50px 40px;
            height: 100%;

            box-sizing: border-box;
            position: relative;

            .text1 {
                font-size: 16px;
                font-weight: bold;
                color: #ffffff;
                line-height: 26px;
            }

            .text2 {
                font-size: 14px;
                color: #ffffff;
                line-height: 26px;
                margin-top: 16px;
            }

            &::after {
                content: 'CASE';
                font-size: 30px;
                color: #ffffff;
                line-height: 26px;
                position: absolute;
                right: 40px;
                bottom: 56px;
            }

            img {
                width: 146px;
                height: 20px;
                position: absolute;
                bottom: 30px;
                right: 40px;
            }

            .paginations {
                position: absolute;
                left: 40px;
                bottom: 40px;
                display: flex;
                align-items: center;
                transition: all ease-in-out 0.3s;

                .pagination-item {
                    width: 20px;
                    height: 20px;
                    background-color: rgba(0, 0, 0, 0.16);
                    border-radius: 10px;
                    font-size: 12px;
                    color: #fff;
                    line-height: 20px;
                    text-align: center;
                    cursor: pointer;
                    margin-right: 8px;
                    transition: all ease-in-out 0.3s;

                    &.active {
                        background-color: #fff;
                        color: #8f553e;
                    }
                }
            }
        }
    }

    .service-right {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 150px;
        margin-left: 15px;
        .swiper {
            flex-shrink: 0;
            &.service_img0,
            &.service_img1,
            &.service_img2,
            &.service_img3 {
                width: 150px;
                height: 123px;
                position: relative;
                z-index: 1;
            }

            .showTextImg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .service-center {
        height: 400px;
        width: 645px;
        flex-shrink: 0;
        .swiper {
            &.swiperBig {
                height: 400px;
            }

            .showTextImg {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.isEn {
    .service-box .service-left .service-text {
        padding: 40px 20px 100px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .text1 {
            font-size: 14px;
        }
        .text2 {
            font-size: 12px;

            flex-grow: 1;
            overflow: auto;
        }

        .paginations {
            left: 20px;
        }
    }
}
</style>

<style lang="scss">
@keyframes ZoomInTopRight {
    from {
        opacity: 0;
        transform: scale(0.5) translate(150%, -50%);
    }

    to {
        opacity: 1;
        transform: scale(1) translate(0, 0);
    }
}
@keyframes ZoomInBottomRight {
    from {
        opacity: 0;
        transform: scale(0.5) translate(150%, 50%);
    }

    to {
        opacity: 1;
        transform: scale(1) translate(0, 0);
    }
}
.ZoomInTopRight {
    animation-name: ZoomInTopRight;
}
.ZoomInBottomRight {
    animation-name: ZoomInBottomRight;
}
</style>
