<template>
    <div class="label" :class="{ active: isChoose }">
        <el-button
            circle
            :type="isChoose ? 'primary' : 'info'"
            :plain="!isChoose"
        >
            <span class="iconfont" v-html="icon"> </span>
        </el-button>
        <div class="name"><slot name="title"></slot></div>
    </div>
</template>

<script>
export default {
    name: 'Label',
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        chooseValue: {
            type: [String, Number],
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
    },
    computed: {
        isChoose() {
            return Number(this.value) === Number(this.chooseValue);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.el-button {
    padding: 20px;
    .iconfont {
        font-size: 50px;
    }
}

.el-button--info {
    background-color: transparent;
    &:hover {
        background-color: $--color-primary;
    }
}

.label {
    display: flex;
    flex-direction: column;
    align-items: center;

    .name {
        font-size: 14px;
        line-height: 26px;
        padding: 20px 0;
        color: #565b66;
    }
}

.active {
    .name {
        color: $--color-primary;
        font-weight: bold;
    }
}
</style>
