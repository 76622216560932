<template>
    <div class="showTextImg" @click="imgClick" @mouseover="mouseoverEvent" @mouseout="mouseoutEvent">
        <el-image :src="img" fit="cover"></el-image>
        <div class="box" v-if="!order && !noName">
            <div class="title">
                {{ name }}
            </div>
            <div class="box-main">
                <div class="sub" v-html="content"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShowTextImg',
    props: {
        name: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        img: {
            type: String,
            default: ''
        },
        order: {
            type: Number,
            default: 0
        },
        noName: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        imgClick() {
            this.$emit('imgClick', {
                img: this.img,
                el: this.$el
            });
        },
        mouseoverEvent() {
            this.$emit('mouseover');
        },
        mouseoutEvent() {
            this.$emit('mouseout');}
    }
};
</script>

<style lang="scss" scoped>
.showTextImg {
    overflow: hidden;
    position: relative;
    cursor: pointer;

    .el-image {
        width: 100%;
        height: 100%;
        transition: transform ease-in-out 0.3s;
        transform: translate3d(0, 0, 0);
    }

    .box {
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        padding: 10px 20px;

        display: flex;
        flex-direction: column;

        transform: translateY(352px);

        transition: transform ease-in-out 0.3s;

        .box-main {
            margin-top: 20px;
            // flex-grow: 1;
            overflow-y: auto;
            padding: 20px 55px;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
                border-radius: 2px;
            }
            &::-webkit-scrollbar-thumb {
                background: #bfbfbf;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #333;
            }
        }

        .title {
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            line-height: 26px;
        }

        .sub {
            font-size: 14px;
            color: #ffffff;
            line-height: 26px;
        }
    }

    &.animated {
        .box {
            // display: none;
            transform: translateY(100%);
        }
        &:hover {
            .box {
                // display: none;

                transform: translateY(100%);
            }
        }
    }

    &:hover {
        .box {
            padding: 30px 20px;
            transform: translateY(0);
            background: rgba(0, 0, 0, 0.7);
            justify-content: center;
            .title {
                text-align: center;
            }
        }

        .el-image {
            transform: scale(1.1);
        }
    }
}
</style>
