<template>
    <div class="advantage">
        <div class="bg" :style="{ backgroundImage: 'url(' + img + ')' }"></div>
        <div class="content">
            <img class="img" :src="icon" alt="" />
            <div class="text" v-html="name"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Advantage',
    props: {
        img: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
.advantage {
    height: 330px;
    overflow: hidden;
    position: relative;

    .bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        transition: all ease-in-out 0.3s;
        transform: translate3d(0, 0, 0);
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;
        position: relative;
        width: 100%;
        height: 100%;

        .img {
            width: 72px;
            height: 72px;
            margin-top: 70px;
            flex-shrink: 0;
        }

        .text {
            flex-grow: 1;

            font-size: 14px;
            color: #ffffff;
            line-height: 24px;
            padding: 0 23px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &:hover {
        .bg {
            transform: scale(1.1);
        }
    }
}

.isEn {
    .advantage .content .text {
        font-size: 12px;
        padding: 0 15px;
        line-height: 18px;
    }
}
</style>
