<template>
    <el-row class="serviceContent2">
        <el-col :span="12">
            <div class="left" v-html="getName(info.content)" :style="{ backgroundColor: info.color }"></div>
        </el-col>
        <el-col :span="12">
            <ShowTextImg :img="info.img" noName />
        </el-col>
    </el-row>
</template>

<script>
import ShowTextImg from './ShowTextImg';
export default {
    name: 'Service',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        name: {
            type: String,
            default: ''
        },
        chooseValue: {
            type: String,
            default: ''
        }
    },
    components: {
        ShowTextImg
    },
    computed: {
        color() {
            return this.info.color;
        }
    }
};
</script>
<style lang="scss" scoped>
.serviceContent2 {
    padding: 30px 0 160px;
    .el-col {
        height: 358px;
    }

    .left {
        font-size: 14px;
        color: #ffffff;
        line-height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2c74bf;
        height: 100%;
        padding: 30px;
        box-sizing: border-box;
        text-align: center;
    }
}

.showTextImg {
    height: 100%;
}
</style>
