<template>
    <div class="box-content core">
        <div class="box-img">
            <swiper class="swiper" :options="options">
                <swiper-slide v-for="(item, index) in info.imgs" :key="index">
                    <auto-img :src="item"></auto-img>
                </swiper-slide>
                <div class="swiper-pagination swiper-pagination-white" slot="pagination" v-siwperPagination></div>
            </swiper>
        </div>
        <div class="box-text">
            <div class="text1">
                {{ getName(info.title) }}
            </div>
            <div class="text2">
                {{ getName(info.sub) }}
            </div>
            <div class="text3 scrollbar" v-html="getName(info.content)"></div>

            <img :src="require(`../../assets/IMT/0${index}.png`)" class="img" alt="" />
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
export default {
    name: 'core',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        index: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            options: {
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass: 'banner-bullet',
                    bulletActiveClass: 'banner-bullet-active'
                }
            }
        };
    },
    components: {
        Swiper,
        SwiperSlide
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

$colors: [#8f553e, #618682, #dc8b30, #52709d, #c46354];
$color2s: [rgba(255, 165, 38, 1), #77beb6, #eca453, #6c8fc4, #ea9386];

$left-padding: 0.86rem;
$right-padding: 0.6rem;

.box-content {
    display: flex;
    // max-width: $--page-center;
    @include center-content(410);
    position: relative;
    height: 400px;
    margin-top: 100px;
    .box-img {
        position: relative;
        z-index: 2;
        overflow: hidden;
        width: 740px;
        height: 400px;
        flex-shrink: 0;
        .swiper {
            width: 100%;
            height: 100%;
        }
    }
    .box-text {
        flex-grow: 1;
        position: relative;
        z-index: 2;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: all ease-in-out 0.3s;
        padding: 30px;

        .text1 {
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
            line-height: 33px;
        }

        .text2 {
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            line-height: 24px;
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            &::after {
                content: '';
                width: 50px;
                height: 8px;
                margin-top: 12px;
                display: block;
                transition: all ease-in-out 0.3s;
            }
        }

        .text3 {
            font-size: 14px;
            color: #ffffff;
            line-height: 24px;
            margin-top: 40px;
            max-height: 200px;
            overflow: auto;
        }

        .img {
            width: 66px;
            height: 66px;
            position: absolute;
            top: 30px;
            z-index: 3;
        }

        &::after {
            content: '';
            position: absolute;
            top: -32px;
            width: 2px;
            height: 120px;
            transition: all ease-in-out 0.3s;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
    }

    .swiper-pagination {
        padding: 0 0.3rem;
        box-sizing: border-box;
        bottom: 0.2rem;
    }

    @each $color in $colors {
        $index: index($colors, $color);
        &:nth-child(5n + #{$index}) {
            &::after {
                background-color: $color;
            }

            .box-text {
                &::after {
                    background-color: nth($color2s, $index);
                }

                .text2 {
                    &::after {
                        background-color: nth($color2s, $index);
                    }
                }
            }
        }
    }

    &:nth-child(2n + 1) {
        flex-direction: row;
        &::after {
            right: -50%;
        }

        .text3 {
            margin-right: -100px;
        }

        .img {
            right: 16px;
        }
        .box-text {
            &::after {
                right: 0px;
            }
        }
    }
    &:nth-child(2n) {
        flex-direction: row-reverse;
        &::after {
            left: -50%;
        }
        .text3 {
            margin-left: -100px;
        }

        .img {
            left: 16px;
        }

        .box-text {
            text-align: right;
            &::after {
                left: 0px;
            }

            .text2 {
                align-items: flex-end;
            }
        }
    }

    &:hover {
        .box-text {
            &::after {
                top: -10px;
            }

            .text2 {
                &::after {
                    width: 70px;
                }
            }
        }
    }
}

@media screen and (max-width: 1550px) {
    .box-content {
        &:nth-child(2n + 1) {
            .text3 {
                margin-right: 0px;
            }
        }
        &:nth-child(2n) {
            .text3 {
                margin-left: 0px;
            }
        }
    }
}

.isEn {
    .box-content .box-text {
        .text1 {
            font-size: 20px;
        }
        .text2 {
            font-size: 12px;
        }
        .text3 {
            font-size: 12px;
            margin-top: 20px;
        }
    }
}
</style>

<style lang="scss">
.core {
    .swiper {
        .banner-bullet {
            width: 20px;
            height: 3px;
            margin: 0 4px;
        }

        .banner-bullet-active {
            width: 30px;
            height: 3px;
        }
    }
}
</style>
